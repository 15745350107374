const searchInputs = document.querySelectorAll('.input-group--search')
searchInputs.forEach((searchInputGroup) => {
    const input = searchInputGroup.querySelector('.input-group__input')
    const clearBtn = searchInputGroup.querySelector('.input-group__clear')
    // clear input text by clicking the on "x" button
    clearBtn.addEventListener('click', (event) => {
        event.preventDefault()
        input.value = ''
        input.focus()
        clearBtn.classList.remove('input-group__clear--active')
    })

    // Check for input changes
    input.addEventListener('input', function () {
        clearBtnVisibility()
    })
    
    // Check if the input was focused
    input.addEventListener("focus", function (){
        clearBtnVisibility()
    })
    
    function clearBtnVisibility(){
        // Show the "x" button only if there input has text
        if(input.value!==''){
            clearBtn.classList.add('input-group__clear--active')
        } else {
            clearBtn.classList.remove('input-group__clear--active')
        }
    }
})

// Search results auto focus
document.addEventListener("DOMContentLoaded", function () {
    if (window.screen.width >= 1024) {
        const searchResultsInput = document.querySelector('.search-results__input input')
        if (searchResultsInput != null) {
            searchResultsInput.setSelectionRange(searchResultsInput.value.length, searchResultsInput.value.length)
            searchResultsInput.focus()
        }
    }
})