document.addEventListener("DOMContentLoaded", function () {
    // Add click event listener to all buttons with class "copy-button"
    const copyButtons = document.querySelectorAll('.copy-button')

    copyButtons.forEach((button) => {
        button.addEventListener('click', (event) => {
            event.preventDefault()
            // Copy the URL to clipboard
            const urlToCopy = window.location.href;
            navigator.clipboard.writeText(urlToCopy).then(() => {
                // URL copied successfully
                // Add the "active" class to the feedback element
                const feedback = button.querySelector('.copy-button__feedback')
                feedback.classList.add('active')

                // Remove the "active" class after 1.8 seconds
                setTimeout(() => {
                    feedback.classList.remove('active')
                }, 1800)
            })
        })
    })
})