document.addEventListener("DOMContentLoaded", function () {
    // Add click event listener to all buttons with class "copy-button"
    const eventFilters = document.querySelectorAll('.event-overview__filter')

    eventFilters.forEach((filter) => {
        const inputs = filter.querySelectorAll('.checkbox input')
        const reset = filter.querySelector('.event-overview__reset')

        inputs.forEach(input => {
            input.addEventListener('change', e => {
                const checkedInputs = Array.from(inputs).filter(input => input.checked)

                // check if at least one input is checked and show/hide the "reset" button
                if (checkedInputs.length > 0) {
                    reset.classList.add('event-overview__reset--active')
                } else {
                    reset.classList.remove('event-overview__reset--active')
                }
            })
        })

        // uncheck all filter inputs by clicking the "reset" button
        reset.addEventListener('click', (event) => {
            event.preventDefault()

            // Uncheck all the filter inputs
            inputs.forEach(input => {
                input.checked = false
            })

            // Hide all filter inputs
            reset.classList.remove('event-overview__reset--active')
        })
    })
})