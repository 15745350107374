document.addEventListener("DOMContentLoaded", function() {
    const alertBanners = document.querySelectorAll('.alert-banner')
    if(alertBanners != null && alertBanners.length > 0){
        alertBanners.forEach(banner => {
            // Hide banner on "x" click
            const closeBtn = banner.querySelector('.alert-banner__close')
            if(closeBtn != null){
                closeBtn.onclick = () => {
                    banner.style.display = 'none'
                }
            }
        })
    }
})