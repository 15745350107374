const tabs = document.querySelectorAll('.content-tabs__button');
const tabContents = document.querySelectorAll('.content-tabs__content');

tabs.forEach((tab, index) => {
  tab.addEventListener('click', (e) => {
    tabContents.forEach(c => c.classList.remove('active'))
    
    tabContents[index].classList.add('active')
    
    tabs.forEach(t => {
      t.setAttribute('aria-selected', 'false')
      t.blur(); // Remove focus from all buttons
    })
    tab.setAttribute('aria-selected', 'true')
    tab.blur(); // Remove focus from the clicked button
  })
})