function handleFileInput(inputGroup){
    
    // Find the input-file-wrapper element inside each input-group--file
    const input = inputGroup.querySelector('input')
    var inputWrapper;
    var fileUploadButton;
    var canDelete = true
    var canDrop = true
    
    // Check if it is a Hubspot form
    if(input.classList.contains('hs-input')){
        canDelete = false
        canDrop = false
        fileUploadButton = inputGroup.querySelector('.input')
    } else {
        inputWrapper = inputGroup.querySelector(".input-file-wrapper")
        fileUploadButton = inputGroup.querySelector('.btn')
    }

    // Add event listener for button click to trigger the file input
    fileUploadButton.addEventListener("click", () => {
        input.click()
    })

    if(canDrop){
        // Add event listener for dragover events
        inputWrapper.addEventListener("dragover", (e) => {
            e.preventDefault()
            inputWrapper.classList.add("input-file-wrapper--dragover")
        })
    
        // Add event listener for dragleave events
        inputWrapper.addEventListener("dragleave", () => {
            inputWrapper.classList.remove("input-file-wrapper--dragover")
        })

        // Add event listener for drop events
        inputWrapper.addEventListener("drop", (e) => {
            e.preventDefault()
            inputWrapper.classList.remove("input-file-wrapper--dragover")
        
            // Get the dropped files from the event
            const droppedFiles = e.dataTransfer.files
        
            // Call handleDroppedFiles function to handle dropped files
            handleDroppedFiles(droppedFiles)
        })
    }


    // ------------------------------------------------------------------------------------
    // "File list" functionality:
    // ------------------------------------------------------------------------------------

    let filesArray = []
    const fileList = inputGroup.querySelector('.file-list')

    // Function to handle dropped files
    function handleDroppedFiles(files) {
        for (let i = 0; i < files.length; i++) {
            addFileToList(files[i])
        }
    }

    // Function to handle file selection (from input change event)
    function handleFileSelect(event) {
        // Check if the "delete" funcionality is enabled. If not, clear the array
        if(canDelete == false){
            clearFileArray()
        }

        const files = event.target.files
        for (let i = 0; i < files.length; i++) {
            addFileToList(files[i])
        }
    }

    // Function to add file to the list
    function addFileToList(file) {
        const li = document.createElement('li')
        let buttonContent = ''

        // Check if the "delete" funcionality is enabled
        if(canDelete){
            // if true, create the "delete" button
            buttonContent = `<button type="button" class="btn btn--tertiary delete-btn" data-file="${file.name}"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon" aria-hidden="true"><g clip-path="url(#clip0_5236_48953)"><path d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z" fill="#212121"></path></g><defs><clipPath id="clip0_5236_48953"><rect width="24" height="24" fill="white"></rect></clipPath></defs></svg><span>Slett vedlegg</span></button>`
        }

        // Push the file object to the filesArray
        filesArray.push(file);

        li.classList.add('file-list__item')
        li.innerHTML = `
        <p>${file.name}</p>
        ${buttonContent}
        `;
        fileList.appendChild(li)

        // Event listener for delete button
        if(canDelete){
            li.querySelector('.delete-btn').addEventListener('click', function(e){
                deleteFile(e)
            })
        }
    }

    function deleteFile(event) {
        event.preventDefault();
    
        // Find the closest ancestor element with the 'data-file' attribute
        const deleteButton = event.target.closest('[data-file]');
    
        if (deleteButton) {
            const fileName = deleteButton.getAttribute('data-file');
    
            // Filtering the filesArray based on the file name
            filesArray = filesArray.filter(file => file.name !== fileName);
    
            // Remove the element with the 'data-file' attribute from the DOM
            deleteButton.closest('.file-list__item').remove()
        } else {
            console.error('Element with data-file attribute not found.');
        }
    }

    // Function to clear the files array
    function clearFileArray(){
        filesArray.length = 0
        fileList.innerHTML = ''
    }

    // Event listener for file input change
    input.addEventListener('change', (e) => {
        handleFileSelect(e)
    })
}

// Get all elements with class "input-group--file"
const fileInputGroups = document.querySelectorAll(".input-group--file")

// Loop through each input-group--file element
fileInputGroups.forEach((inputGroup) => {
    handleFileInput(inputGroup)
})