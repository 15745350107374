// Video block
const videoBlocks = document.querySelectorAll('.video-block')

videoBlocks.forEach((videoBlock) => {
    let playButton = videoBlock.querySelector('.video-block__play')
    let video = videoBlock.querySelector('iframe, video') // Select either iframe or video
    let src = videoBlock.getAttribute('data-id')

    // Check window width to set the correct "mute" attribute value
    let mute = window.innerWidth > 1023 ? 0 : 1

    if (videoBlock.classList.contains('video-block--no-image')) {
        videoBlock.classList.add('active')

        // Check if video is an iframe
        if (video.tagName.toLowerCase() === 'iframe') {
            video.setAttribute('src', 'https://www.youtube.com/embed/' + src + `?autoplay=0&playsinline=1&mute=${mute}&loop=1&playlist=` + src)
        }

    } else {
        playButton.onclick = () => {
            videoBlock.classList.add('active')

            // Check if video is an iframe
            if (video.tagName.toLowerCase() === 'iframe') {
                video.setAttribute('src', 'https://www.youtube.com/embed/' + src + `?autoplay=1&playsinline=1&mute=${mute}&loop=1&playlist=` + src)
            }
            // Check if video is a video tag
            else if (video.tagName.toLowerCase() === 'video') {
                video.play()
                video.setAttribute('tabindex', '0')
            }
        }
    }
})
