const modals = document.querySelectorAll('.modal')

// Get all modals
modals.forEach((modal) => {

    // Listen for the "close" event
    modal.addEventListener('hidden.bs.modal', () => {

        // Get all possible media types
        const modalMedia = modal.querySelectorAll('iframe, video, audio')

        modalMedia.forEach((mediaType) => {
            // Stop the video if it is an iframe
            if (mediaType.tagName.toLowerCase() === 'iframe') {
                const videoBlock = mediaType.closest('.video-block')
                mediaType.setAttribute('src', '')
                videoBlock.classList.remove('active')
            }
            
            // Stop the video if it is an uploaded video
            if(mediaType.tagName.toLocaleLowerCase() === 'video'){
                const videoBlock = mediaType.closest('.video-block')
                mediaType.pause()
                mediaType.currentTime = 0;
                mediaType.setAttribute('tabindex', '-1')
                videoBlock.classList.remove('active')
            }
            
            // Stop the audio
            if(mediaType.tagName.toLocaleLowerCase() === 'audio'){
                mediaType.pause()
                mediaType.currentTime = 0;
            }
        })
    })
    
    // Trap focus inside the modal
    function handleTrapEvent(lastFocusableElement, firstFocusableElement) {
        return function eventHandler(event) {
            const isTabPressed = event.key === 'Tab' || event.keyCode === 9
    
            if (!isTabPressed) {
                return
            }
    
            if (event.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus()
                    event.preventDefault()
                }
            } else if (document.activeElement === lastFocusableElement) {
                firstFocusableElement.focus()
                event.preventDefault()
            }
        }
    }
    
    function addTrapHandler(modalElement) {
        const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), video, audio'
        const firstFocusableElement = modalElement.querySelector(focusableElements)
        const focusableContent = modalElement.querySelectorAll(focusableElements)
        const lastFocusableElement = focusableContent[focusableContent.length - 1]
    
        document.addEventListener('keydown', handleTrapEvent(lastFocusableElement, firstFocusableElement))
    }
    
    function initTrap() {
        addTrapHandler(modal)
    }
    
    document.addEventListener('DOMContentLoaded', () => {
        initTrap(modal)
    })
})
