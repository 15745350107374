const textareas = document.querySelectorAll('.input-group textarea')
textareas.forEach((textarea) => {
    const maxChars = textarea.getAttribute('data-max')
    const inputGroup = textarea.parentElement
    const currentText = inputGroup.querySelector('.current')
    const maxText = inputGroup.querySelector('.max')

    // Update "current" span text
    currentText.innerHTML = textarea.value.length

    // Update "max" span text
    maxText.innerHTML = maxChars

    textarea.addEventListener('input', function () {
        // Count the number of characters
        var count = textarea.value.length

        // Set the count
        currentText.innerHTML = textarea.value.length

        if (count >= maxChars) {
            // Truncate the value to "maxChars" characters
            textarea.value = textarea.value.slice(0, maxChars);
            currentText.innerHTML = maxChars
        }
    })
})